import React, {
  useState,
  useMemo,
  useCallback,
  useEffect
} from 'react';
import {
  Table,
  Space,
  Input,
  Button
} from 'antd';
import { DeleteFilled } from '@ant-design/icons';
import ButtonUserUnassigned from '../../components/ButtonUserUnassigned';
import ModalUserUnassigned from '../../components/ModalUserUnassigned';
import style from './unassignedusers.module.scss';
import ButtonUserUnassignedDelete from '../../components/ButtonUserUnassignedDelete';

const columns = [
  {
    width: 170,
    title: 'Имя',
    dataIndex: 'firstName'
  },
  {
    width: 170,
    title: 'Фамилия',
    dataIndex: 'lastName'
  },
  {
    width: 170,
    title: 'Телефон',
    dataIndex: 'personalPhone'
  },
  {
    title: 'Email',
    dataIndex: 'personalEmail'
  },
  {
    title: 'СМС Код',
    dataIndex: 'smsCode'
  },
  {
    width: 200,
    title: '',
    dataIndex: '',
    fixed: 'right',
    key: 'select',
    render: (text, record, index) => (
      <div>
        <ButtonUserUnassignedDelete record={record} />
        <ButtonUserUnassigned record={record} />
      </div>
    )
  }
];

const dataFilter = {
  search: null,
  offset: 0,
  limit: 10
};

const UnassignedUsers = ({
  data,
  pagination,
  getListUnassignedUsers
}) => {
  const [search, setSearch] = useState();
  useEffect(() => {
    getListUnassignedUsers(dataFilter);
  }, [getListUnassignedUsers]);

  const handleTableChange = useCallback((pagin, filters, sorter) => {
    getListUnassignedUsers({
      search: search && search.length >= 3,
      offset: (pagin.current - 1) * pagin.pageSize,
      limit: pagin.pageSize
    });
  }, [getListUnassignedUsers, search]);

  const onChangeSearch = useCallback((e) => {
    setSearch(e.target.value);
    if (e.target.value.length >= 3) {
      getListUnassignedUsers({
        search: e.target.value,
        offset: 0,
        limit: pagination.pageSize,
        isSearch: true
      });
    } else {
      getListUnassignedUsers({
        search: null,
        offset: 0,
        limit: pagination.pageSize,
        isSearch: true
      });
    }
  }, [getListUnassignedUsers, pagination.pageSize]);

  const renderSearch = useMemo(() => (
    <Space style={{ marginBottom: 16 }}>
      <Input placeholder="Поиск"
        prefix={<img src="/images/search.svg" alt="Поиск" />}
        value={search}
        onChange={onChangeSearch}
      />
    </Space>
  ), [search, onChangeSearch]);

  const renderTable = useMemo(() => (
    <Table columns={columns}
      rowKey={(record) => record.uuid}
      dataSource={data}
      pagination={pagination}
      onChange={handleTableChange}
      scroll={{ y: '100%', x: 900 }}
    />
  ), [data, handleTableChange, pagination]);

  return (
    <>
      <div className="dd-container">
        <div className={`dd-container-main ${style.container}`}>
          <div className={style.header}>
            <h2>Нераспределённые пользователи</h2>
          </div>
          <div className={style.main}>
            {renderSearch}
            <div className="dd-table">
              {renderTable}
            </div>
          </div>
        </div>
      </div>
      <ModalUserUnassigned />
    </>
  );
};

export default UnassignedUsers;
